import { Component } from '@angular/core';
import { Http } from '@angular/http';

@Component({
  templateUrl: 'build/pages/terms/terms.html'
})
export class TermsPage
{
  private termsInput:string;
  private termsOutput:string;

  constructor(
      private http:Http
  )
  {}

  ngOnInit()
  {
    this.http.get("terms_sample.txt")
        .subscribe((res) => {
          this.termsInput = res['_body'];
        });
  }

  convert()
  {
    let terms = '\n' + this.termsInput;

    // 条項毎に変数に格納
    let i = 0;
    let tmpArticle;
    let splitArray = terms.split(/[\n|\r|\n\r](第(\d{1,2}|[０-９]{1,2})条.*)(\n|\r|\n\r)/g);
    let skipArray = [];


    splitArray.forEach(function(txt:string)
    {
      if(txt.length > 3){
        if(i%2 == 0){
          tmpArticle = txt;
        }else{
          skipArray = skipArray.concat({article:tmpArticle,provision:txt});
        }
        i++;
      }
    });

    // 条項を加工
    let termsArray = [];
    let that = this;
    skipArray.forEach(function(val)
    {

      let term:string;
      term = that._makeList(val.provision,/(\n|\r|\n\r)(第(\d{1,2}|[０-９]{1,2})項|(\d{1,2}|[０-９]{1,2})(\.|．))/g);

      termsArray = termsArray.concat({article:val.article,provision:term});
    });

    let termText = "";
    termsArray.forEach(function(val)
    {
      termText  = termText + '<dt>\n' + val.article + '\n</dt>\n<dd>' + val.provision + '</dd>\n';
    });

    this.termsOutput = '<dl>\n' + termText + '</dl>';
  }


  public _makeList(val:string,expression)
  {
    if(val.match(expression)){
      let spliteArray = val.split(expression);

      // 前文取得
      let preface = "";
      if(spliteArray[0].length > 1){
        preface = spliteArray[0] + '\n';
        delete spliteArray[0];
      }

      let that = this;
      let list = "  <ol>\n";
      spliteArray.forEach(function(txt:string)
      {
        if(txt && txt.length > 3){
          txt = that._makeDetailList(txt,/([Ⅰ-Ⅹ]{1,2}|[ⅰ-ⅹ]{1,2}|(\d{1,2}|[０-９]{1,2})号)/g);
          list += '    <li>\n      ' + txt + '    </li>\n';
        }
      });
      list += '  </ol>\n';

      return preface + list;
    }else{
      return val;
    }
  }

  public _makeDetailList(val:string,expression)
  {
    if(val.match(expression)){
      let spliteArray = val.split(expression);

      // 前文取得
      let preface = "";
      if(spliteArray[0].length > 1){
        preface = spliteArray[0];
        delete spliteArray[0];
      }

      let list = "      <ol>\n";
      spliteArray.forEach(function(txt:string)
      {
        if(txt && txt.length > 3){
          list += '        <li>\n          ' + txt + '        </li>\n';
        }
      });
      list += '      </ol>\n';

      return preface + list;
    }else{
      return val;
    }
  }
}
